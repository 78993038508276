<template>
  <button :class="classes" :type="type" :disabled="disabled" @click="onClick">
    <inline-svg
      v-if="icon"
      class="btn__icon"
      :src="require(`../assets/icons/${icon}.svg`)"
      :aria-label="icon"
    ></inline-svg>
    <slot />
  </button>
</template>

<script>
export const buttonType = {
  SUBMIT: "submit",
  BUTTON: "button"
};

export const buttonTheme = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  CONFIRM: "confirm",
  WARNING: "warning",
  BACKGROUND: "background",
  EXPAND_COLLAPSE: "expandCollapse"
};

export default {
  props: {
    type: {
      type: String,
      default: buttonType.BUTTON,
      validator: value => Object.values(buttonType).includes(value)
    },
    theme: {
      type: String,
      default: buttonTheme.PRIMARY,
      validator: value => Object.values(buttonTheme).includes(value)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    }
  },
  computed: {
    classes() {
      return ["btn", `btn--${this.theme}`];
    }
  },
  methods: {
    onClick() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  @apply flex justify-center items-center border border-transparent rounded-lg px-3 py-2 cursor-pointer text-xs font-semibold;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);

  &:hover {
    @apply bg-ls-gray-100;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.02);
  }

  &--primary {
    @apply bg-ls-purple text-white;
    border: 1px solid #6f81e0;
    &:hover {
      background-color: #6f81e0;
    }
  }

  &--secondary {
    @apply border-ls-gray-400 bg-white;
  }

  &--confirm {
    @apply bg-ls-green text-white;

    &:hover {
      @apply bg-ls-green;
    }
  }

  &--warning {
    @apply bg-ls-red text-white;

    &:hover {
      @apply bg-red-600;
    }
  }

  &--background {
    @apply bg-ls-gray-100 shadow-none;
  }

  &--expandCollapse {
    box-shadow: none;
    padding: 0;
    flex-direction: row-reverse;
    justify-content: space-between;
    background: transparent;
    &:hover {
      background-color: none;
    }
  }

  &__icon {
    margin-right: 0.5rem;
    width: 16px;
    height: 16px;
  }

  &:disabled {
    opacity: 0.6;
  }
}
</style>
