<template>
  <Modal
    v-if="showChangeLog"
    class="change-log"
    @close="onCloseChangeLog"
    :heading="$t('components.changeLog.heading')"
  >
    <template #modal-content>
      <div class="change-log__sub-heading">
        {{ $t("components.changeLog.subHeading") }}
      </div>
      <div class="change-log__content" v-html="logData.content"></div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Modal
  },
  computed: {
    ...mapGetters("user", {
      user: "getUserProfile",
      logData: "getLogData"
    }),
    userLogId() {
      return this.user?.lastSeenLogId;
    },
    showChangeLog() {
      if (this.user && this.logData) {
        return this.userLogId && this.userLogId === this.logData.id
          ? false
          : true;
      }

      return false;
    }
  },
  methods: {
    ...mapActions("user", ["updateProfile"]),
    onCloseChangeLog() {
      this.updateProfile({ lastSeenLogId: this.logData.id });
    }
  }
};
</script>

<style lang="scss">
.change-log {
  &__sub-heading {
    @apply font-medium my-2;
  }
  &__content {
    li {
      list-style-type: circle;
      display: list-item;
      margin-left: 16px;
    }
  }
}
</style>
