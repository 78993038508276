import Vue from "vue";
import App from "./App";
import router from "./router";
import { store } from "./store/index";
import "@/assets/css/tailwind.css";
import firebase from "firebase";
import i18next from "i18next";
import VueI18Next from "@panter/vue-i18next";
import translations from "./i18n/translations";
import { InlineSvgPlugin } from "vue-inline-svg";
import VueCroppie from "vue-croppie";
import "croppie/croppie.css";
import TextareaAutosize from "vue-textarea-autosize";

Vue.use(VueCroppie);
Vue.use(InlineSvgPlugin);
Vue.use(VueI18Next);
Vue.use(TextareaAutosize);

const DEFAULT_LNG = "en";

i18next.init({
  lng: "en",
  fallbackLng: DEFAULT_LNG,
  resources: translations
});

const i18n = new VueI18Next(i18next);

Vue.config.productionTip = false;

let app = null;

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      el: "#app",
      router,
      store,
      i18n: i18n,
      render: h => h(App)
    });
  }
});
