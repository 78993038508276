export const blobUrlToBase64 = async blobUrl => {
  const response = await fetch(blobUrl);
  const blob = await response.blob();
  return this.blobToBase64(blob);
};

export const blobToBase64 = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(",")[1]);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
