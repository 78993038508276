var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-bar--mobile",class:{ 'nav-bar--open': _vm.showNavigation || _vm.showTeams }},[_c('div',{staticClass:"nav-bar--mobile__header"},[_c('div',{staticClass:"nav-bar--mobile__button--navigation"},[(!_vm.showNavigation && !_vm.showTeams)?_c('inline-svg',{staticClass:"nav-bar--mobile__button-icon",attrs:{"src":require(`../assets/icons/Drawer.svg`)},on:{"click":function($event){_vm.showNavigation = true}}}):_vm._e(),(_vm.showNavigation || _vm.showTeams)?_c('inline-svg',{staticClass:"nav-bar--mobile__button-icon",attrs:{"src":require(`../assets/icons/X.svg`)},on:{"click":_vm.closeNavBar}}):_vm._e()],1),(!_vm.showNavigation && !_vm.showTeams)?_c('div',{staticClass:"nav-bar--mobile__logo"},[_c('inline-svg',{staticClass:"nav-bar--mobile__link-icon",attrs:{"src":require(`../assets/icons/LobbySpace.svg`)}})],1):_vm._e(),(!_vm.showTeams && !_vm.showNavigation)?_c('div',{staticClass:"nav-bar--mobile__button--teams",on:{"click":function($event){_vm.showTeams = true}}},[(_vm.currentTeam.avatar)?_c('div',{staticClass:"nav-bar--mobile__chosen-logo",style:({
          'background-image': 'url(' + _vm.currentTeam.avatar + ')'
        })}):_c('ReplacementLogo',{staticClass:"nav-bar--mobile__chosen-logo--unset",attrs:{"name":_vm.currentTeam.name}})],1):_vm._e()]),(_vm.showNavigation)?_c('div',{staticClass:"nav-bar--mobile__content nav-bar--mobile__links"},[_vm._l((_vm.links),function(link,linkIndex){return _c('div',{key:linkIndex,staticClass:"nav-bar--mobile__item",class:{
        'nav-bar--mobile__item--active': _vm.$route.path === '/' + link.link
      },on:{"click":_vm.closeNavBar}},[_c('router-link',{staticClass:"nav-bar--mobile__link",attrs:{"to":link.link}},[(link.icon)?_c('inline-svg',{staticClass:"nav-bar--mobile__link-icon",attrs:{"src":require(`../assets/icons/${link.icon}.svg`),"aria-label":link.icon}}):_vm._e(),_vm._v(" "+_vm._s(link.name)+" ")],1)],1)}),_c('div',{staticClass:"nav-bar--mobile__divider"}),_c('div',{staticClass:"nav-bar__footer"},[_c('div',{staticClass:"nav-bar__item",class:{ 'nav-bar__item--active': _vm.$route.name === 'Settings' },on:{"click":_vm.closeNavBar}},[_c('router-link',{staticClass:"nav-bar__link",attrs:{"to":"/settings/account"}},[_c('inline-svg',{staticClass:"nav-bar__link-icon",attrs:{"src":require(`../assets/icons/Settings.svg`),"aria-label":"Settings"}}),_vm._v(" Settings ")],1)],1),_c('div',{staticClass:"nav-bar__item",class:{ 'nav-bar__item--active': _vm.$route.name === 'Support' },on:{"click":_vm.closeNavBar}},[_c('router-link',{staticClass:"nav-bar__link",attrs:{"to":"/support"}},[_c('inline-svg',{staticClass:"nav-bar__link-icon",attrs:{"src":require(`../assets/icons/Support.svg`),"aria-label":"Settings"}}),_vm._v(" Support ")],1)],1),_c('a',{staticClass:"nav-bar__link",attrs:{"href":"https://support.lobbyspace.me/","target":"_blank"},on:{"click":_vm.closeNavBar}},[_c('inline-svg',{staticClass:"nav-bar__link-icon",attrs:{"src":require(`../assets/icons/Knowledge base.svg`),"aria-label":"KnowledgeBase"}}),_vm._v(" Knowledge Base ")],1)])],2):_vm._e(),(_vm.showTeams)?_c('div',{staticClass:"nav-bar--mobile__content nav-bar--mobile__teams"},[_vm._l((_vm.teams),function(team,teamIndex){return _c('div',{key:teamIndex,staticClass:"nav-bar--mobile__teams__item",on:{"click":function($event){return _vm.chooseTeam(team)}}},[_c('div',{staticClass:"nav-bar--mobile__teams__item-team"},[(team.avatar)?_c('div',{staticClass:"nav-bar--mobile__teams__item-team-logo",style:({
            'background-image': 'url(' + team.avatar + ')'
          })}):_c('ReplacementLogo',{staticClass:"nav-bar--mobile__teams__item-team-logo--unset",attrs:{"name":team.name}}),_c('div',{staticClass:"nav-bar--mobile__teams__name"},[_vm._v(_vm._s(team.name))])],1),_c('div',{staticClass:"nav-bar--mobile__teams__divider"})])}),_c('div',{staticClass:"nav-bar--mobile__teams__logout",on:{"click":_vm.onLogout}},[_c('inline-svg',{staticClass:"nav-bar--mobile__teams__logout-icon",attrs:{"src":require(`../assets/icons/Logout.svg`),"aria-label":"Down"}}),_vm._v(" "+_vm._s(_vm.$t("components.teamDropdown.logout"))+" ")],1)],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }