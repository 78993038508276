export default {
  namespaced: true,
  state: {
    lansdcape: 0,
    portrait: 0,
    picture: 0
  },
  getters: {
    getProgressLandscape(state) {
      return state.lansdcape;
    },
    getProgressPortrait(state) {
      return state.portrait;
    },
    getProgressPicture(state) {
      return state.picture;
    }
  },
  mutations: {
    SET_PROGRESS_LANDSCAPE(state, payload) {
      state.lansdcape = payload;
    },
    SET_PROGRESS_PORTRAIT(state, payload) {
      state.portrait = payload;
    },
    SET_PROGRESS_PICTURE(state, payload) {
      state.picture = payload;
    }
  },
  actions: {
    updateProgress({ commit }, type) {
      switch (type) {
        case "landscape":
          commit("SET_PROGRESS_LANDSCAPE", 0);
          break;

        case "portrait":
          commit("SET_PROGRESS_PORTRAIT", 0);
          break;

        case "picture":
          commit("SET_PROGRESS_PICTURE", 0);
          break;

        default:
          break;
      }
    }
  }
};
