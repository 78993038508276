<template>
  <div class="replacement-logo" :style="{ 'background-color': color }">
    {{ first }}
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ""
    }
  },
  computed: {
    first() {
      return this.name.charAt(0);
    },
    color() {
      let f = String(this.first.toLowerCase());

      if (f === "a" || f === "g" || f === "m" || f === "s" || f === "y") {
        return "#10b981";
      } else if (
        f === "b" ||
        f === "h" ||
        f === "n" ||
        f === "t" ||
        f === "z"
      ) {
        return "#0ea5e9";
      } else if (f === "c" || f === "i" || f === "o" || f === "u") {
        return "#8b5cf6";
      } else if (f === "d" || f === "j" || f === "p" || f === "v") {
        return "#ef4444";
      } else if (f === "e" || f === "k" || f === "q" || f === "w") {
        return "#ec4899";
      } else if (f === "f" || f === "l" || f === "r" || f === "x") {
        return "#f59e0b";
      }

      return "#10b981";
    }
  }
};
</script>

<style lang="scss">
.replacement-logo {
  @apply flex justify-center items-center text-sm text-white;
}
</style>
