import { db } from "../firebase/init";

export default {
  namespaced: true,
  state: {
    templates: undefined,
    parentTemplates: [],
    rootTemplates: [],
    lobbySpaceTemplates: [],
    singleTemplate: null,
    tempTemplate: null
  },
  getters: {
    getTemplates(state) {
      return state.templates;
    },
    getParentTemplates(state) {
      return state.parentTemplates;
    },
    getRootTemplates(state) {
      return state.rootTemplates;
    },
    getLobbySpaceTemplates(state) {
      return state.lobbySpaceTemplates;
    },
    getSingleTemplate(state) {
      return state.singleTemplate;
    },
    getTempTemplate(state) {
      return state.tempTemplate;
    }
  },
  mutations: {
    SET_TEMPLATES(state, payload) {
      state.templates = payload;
    },
    SET_PARENT_TEMPLATES(state, payload) {
      state.parentTemplates = payload;
    },
    SET_ROOT_TEMPLATES(state, payload) {
      state.rootTemplates = payload;
    },
    SET_LOBBYSPACE_TEMPLATES(state, payload) {
      state.lobbySpaceTemplates = payload;
    },
    SET_SINGLE_TEMPLATE(state, payload) {
      state.singleTemplate = payload;
    },
    SET_TEMP_TEMPLATE(state, payload) {
      state.tempTemplate = JSON.parse(JSON.stringify(payload));
    }
  },
  actions: {
    setTempTemplate({ commit }, payload) {
      commit("SET_TEMP_TEMPLATE", payload);
    },
    fetchTemplates({ commit }, teamId) {
      const templateRef = db.ref(`templates/${teamId}`);
      templateRef.on("value", snapshot => {
        const data = snapshot.val();
        if (data) {
          let templates = Object.entries(data.canvas).map(([id, value]) => ({
            id,
            data: value
          }));
          commit("SET_TEMPLATES", templates);
        } else {
          commit("SET_TEMPLATES", []);
        }
      });
    },
    fetchParentTemplates({ commit }, teamId) {
      const templateRef = db.ref(`templates/${teamId}`);
      templateRef.on("value", snapshot => {
        const data = snapshot.val();
        if (data) {
          let templates = Object.entries(data.canvas).map(([id, value]) => ({
            id,
            data: value
          }));
          commit("SET_PARENT_TEMPLATES", templates);
        }
      });
    },
    fetchRootTemplates({ commit }, teamId) {
      const templateRef = db.ref(`templates/${teamId}`);
      templateRef.on("value", snapshot => {
        const data = snapshot.val();
        if (data) {
          let templates = Object.entries(data.canvas).map(([id, value]) => ({
            id,
            data: value
          }));
          commit("SET_ROOT_TEMPLATES", templates);
        }
      });
    },
    fetchLobbySpaceTemplates({ commit }) {
      const templateRef = db.ref(`templates/-NJMS5tyw2TTSzSv9eMN`);
      templateRef.on("value", snapshot => {
        const data = snapshot.val();
        if (data) {
          let templates = Object.entries(data.canvas).map(([id, value]) => ({
            id,
            data: value
          }));
          commit("SET_LOBBYSPACE_TEMPLATES", templates);
        }
      });
    },
    fetchSingleTemplate({ commit, state }, payload) {
      if (payload.teamId && state.singleTemplate === null) {
        const templateRef = db.ref(
          `templates/${payload.teamId}/canvas/${payload.templateId}`
        );
        return new Promise(resolve => {
          templateRef.on("value", snapshot => {
            let template = snapshot.val();
            template.id = payload.templateId;
            if (template) {
              commit("SET_SINGLE_TEMPLATE", template);
              commit("SET_TEMP_TEMPLATE", template);
            }
            resolve(template);
          });
        });
      }
    },
    resetTemplate({ commit }) {
      commit("SET_SINGLE_TEMPLATE", null);
    },
    duplicateTemplate({ dispatch }, payload) {
      const templateRef = db.ref(
        `templates/${payload.teamId}/canvas/${payload.templateId}`
      );

      templateRef.update(payload.templateData).then(() => {
        dispatch(
          "notifications/addNotification",
          {
            title: "templateDuplicated",
            type: "success",
            autoRemove: true
          },
          { root: true }
        );
      });
    },
    importTemplate({ dispatch }, payload) {
      const templateRef = db.ref(
        `templates/${payload.teamId}/canvas/${payload.templateId}`
      );

      templateRef.update(payload.templateData).then(() => {
        dispatch(
          "notifications/addNotification",
          {
            title: "templateImported",
            type: "success",
            autoRemove: true
          },
          { root: true }
        );
      });
    },
    changeTemplateStatus(_, payload) {
      const templateRef = db.ref(
        `templates/${payload.teamId}/canvas/${payload.id}`
      );
      templateRef.update(payload.template);
    }
  }
};
