var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slide"},[(_vm.slide.type === 'image')?_c('div',{staticClass:"slide__preview-container"},[(!_vm.isOnlyPortrait)?_c('div',{staticClass:"slide__preview",style:({
        'background-image': 'url(' + _vm.slide.url.image.landscape + ')'
      })}):_c('div',{staticClass:"slide__preview--portrait",style:({ 'background-image': 'url(' + _vm.slide.url.image.portrait + ')' })})]):_vm._e(),(_vm.slide.type === 'video')?_c('div',{staticClass:"slide__preview-container"},[(!_vm.isOnlyPortrait)?_c('div',{class:{ slide__preview: !_vm.slide.preview }},[(!_vm.slide.preview)?_c('video',{staticClass:"slide__preview-video",attrs:{"src":_vm.slide.url.video.landscape}}):_c('div',{staticClass:"slide__preview slide__preview--video",style:({
          'background-image': 'url(' + _vm.slide.preview + ')'
        })})]):_c('div',{staticClass:"slide__preview--portrait"},[(!_vm.slide.preview)?_c('video',{staticClass:"slide__preview-video",attrs:{"src":_vm.slide.url.video.portrait}}):_c('div',{staticClass:"slide__preview slide__preview--video",style:({
          'background-image': 'url(' + _vm.slide.preview + ')'
        })})])]):_vm._e(),(_vm.slide.type === 'template')?_c('div',{staticClass:"slide__preview-container"},[(!_vm.slide.preview)?_c('div',{staticClass:"slide__preview slide__preview--template"}):_c('div',{staticClass:"slide__preview slide__preview--template",class:{ 'slide__preview--portrait': _vm.isOnlyPortrait },style:({
        'background-image': 'url(' + _vm.slide.preview + ')'
      })})]):_vm._e(),(_vm.slide.type === 'webview')?_c('div',{staticClass:"slide__preview-container"},[_c('div',{staticClass:"slide__preview slide__preview--webview"})]):_vm._e(),(_vm.slide.type === 'wallsio')?_c('div',{staticClass:"slide__preview-container"},[_c('div',{staticClass:"slide__preview slide__preview--wallsio"})]):_vm._e(),(_vm.isWidget)?_c('div',{staticClass:"slide__preview-container"},[_c('div',{staticClass:"slide__preview slide__preview--widget",style:({
        'background-image': 'url(' + _vm.slide.preview + ')'
      })})]):_vm._e(),_c('div',{staticClass:"slide__title"},[(_vm.slide.type === 'video')?_c('inline-svg',{staticClass:"slide__type-icon",attrs:{"src":require('../assets/icons/SlideType_video.svg'),"aria-label":"Video"}}):_vm._e(),(_vm.slide.type === 'template')?_c('inline-svg',{staticClass:"slide__type-icon",attrs:{"src":require('../assets/icons/SlideType_template.svg'),"aria-label":"Templates"}}):_vm._e(),(_vm.slide.type === 'image')?_c('inline-svg',{staticClass:"slide__type-icon",attrs:{"src":require('../assets/icons/SlideType_image.svg'),"aria-label":"Image"}}):_vm._e(),(_vm.slide.type === 'webview')?_c('inline-svg',{staticClass:"slide__type-icon",attrs:{"src":require('../assets/icons/SlideType_webview.svg'),"aria-label":"Webview"}}):_vm._e(),(_vm.slide.type === 'wallsio')?_c('inline-svg',{staticClass:"slide__type-icon",attrs:{"src":require('../assets/icons/SlideType_wallsio.svg'),"aria-label":"Wallsio"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.slide.title)+" ")],1),(_vm.dateStart && _vm.dateEnd)?_c('div',{staticClass:"slide__date"},[_vm._v(" "+_vm._s(_vm.dateStart)+" - "+_vm._s(_vm.dateEnd)+" ")]):_vm._e(),(_vm.slide.frequency > 0 && _vm.slide.status === 'published')?_c('div',{staticClass:"slide__highlight"},[_c('inline-svg',{staticClass:"slide__frequency-icon",attrs:{"src":require(`../assets/icons/${_vm.frequencyIcon}.svg`),"aria-label":"Frequency Icon"}})],1):_vm._e(),(_vm.slide.status !== 'published')?_c('div',{staticClass:"slide__status"},[_vm._v(" "+_vm._s(_vm.$t(`components.slide.${_vm.slide.status}`))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }