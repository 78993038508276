<template>
  <div class="nav-bar">
    <TeamDropdown />
    <div class="nav-bar__divider"></div>
    <div
      class="nav-bar__item"
      :class="{ 'nav-bar__item--active': $route.name === link.name }"
      v-for="(link, linkIndex) in links"
      :key="linkIndex"
    >
      <router-link
        :to="link.link"
        class="nav-bar__link"
        v-if="isAbleToShow(link)"
      >
        <inline-svg
          class="nav-bar__link-icon"
          v-if="link.icon"
          :src="require(`../assets/icons/${link.icon}.svg`)"
          :aria-label="link.icon"
        ></inline-svg>
        {{ link.name }}
      </router-link>
    </div>
    <div class="nav-bar__divider"></div>
    <div class="nav-bar__footer">
      <div
        class="nav-bar__item"
        :class="{ 'nav-bar__item--active': $route.name === 'Settings' }"
      >
        <router-link to="/settings/account" class="nav-bar__link">
          <inline-svg
            class="nav-bar__link-icon"
            :src="require(`../assets/icons/Settings.svg`)"
            aria-label="Settings"
          ></inline-svg>
          Settings
        </router-link>
      </div>

      <div
        class="nav-bar__item"
        :class="{ 'nav-bar__item--active': $route.name === 'Support' }"
      >
        <router-link to="/support" class="nav-bar__link">
          <inline-svg
            class="nav-bar__link-icon"
            :src="require(`../assets/icons/Support.svg`)"
            aria-label="Settings"
          ></inline-svg>
          Support
        </router-link>
      </div>

      <a
        class="nav-bar__link"
        href="https://support.lobbyspace.me/"
        target="_blank"
      >
        <inline-svg
          class="nav-bar__link-icon"
          :src="require(`../assets/icons/Knowledge base.svg`)"
          aria-label="KnowledgeBase"
        ></inline-svg>
        Knowledge Base
      </a>
    </div>
  </div>
</template>

<script>
import { nav } from "@/schema/sideBar";
import { mapGetters } from "vuex";
import TeamDropdown from "./TeamDropdown";

export default {
  components: {
    TeamDropdown
  },
  computed: {
    ...mapGetters("user", {
      user: "getCurrentUser"
    }),
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      childSettings: "getChildSettings"
    }),
    links() {
      return nav;
    },
    hasParentTeam() {
      return !!this.team?.parentTeam;
    }
  },
  methods: {
    isAbleToShow(link) {
      if (
        link.name === "Brand Manager" &&
        this.team &&
        this.team.users &&
        this.team.users[this.user.uid].role === "editor"
      ) {
        return false;
      }

      if (this.hasParentTeam && link.name === "Brand Manager") {
        if (this.team.users[this.user.uid].role === "editor") {
          return false;
        }
        return this.childSettings?.brandManager;
      }
      return true;
    },
    getLink(link) {
      // if (this.hasParentTeam && this.name === "Templates") {
      //   if(this.childSettings?.)
      // }
      return link.link;
    }
  }
};
</script>

<style lang="scss">
.nav-bar {
  background-color: #fff;
  box-shadow: 1px 0px 0px #f0f0f0;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  padding: 16px 12px;
  width: 208px;
  z-index: 5;

  &__divider {
    height: 1px;
    @apply bg-ls-gray-200 w-full my-2;
  }

  &__item {
    @apply rounded-lg font-semibold text-ls-gray-800 text-xs;
    &:hover {
      @apply bg-ls-gray-100;
      border-radius: 8px;
    }
  }

  &__item {
    .nav-bar__link-icon {
      path,
      rect {
        fill: #7b7b7c;
      }

      path.templates {
        fill: none;
      }

      path.play-button,
      path.brand-manager,
      path.templates {
        fill: #7b7b7c;
      }

      path.message {
        fill: #fff;
      }
    }

    &--active {
      @apply bg-ls-purple text-white;
      &:hover {
        background-color: #6f81e0;
      }
      .nav-bar__link-icon {
        path,
        rect {
          fill: #fff;
        }

        path.templates {
          fill: #fff;
        }

        path.play-button,
        path.brand-manager,
        path.settings {
          fill: #fff;
        }

        path.message {
          fill: #6f81e0;
        }
      }
    }
  }

  &__link {
    @apply px-2 py-3;
    display: flex;
    align-items: center;

    &-icon {
      margin-right: 8px;
      width: 18px;
    }
  }

  &__footer {
    @apply rounded-lg font-semibold text-ls-gray-800 text-xs flex flex-col;

    .nav-bar__link-icon {
      path {
        fill: #7b7b7c;
      }
    }

    .nav-bar__item--active {
      @apply bg-ls-purple text-white;
      &:hover {
        background-color: #6f81e0;
      }
      .nav-bar__link-icon {
        path,
        rect {
          fill: #fff;
        }
      }
    }
  }
}
</style>
