<template>
  <div class="modal">
    <div class="modal__content">
      <div class="modal__heading">
        {{ heading }}
      </div>
      <Button
        class="modal__close-btn"
        @click="onClose"
        v-if="showCloseButton"
        icon="X"
        theme="secondary"
      >
        {{ $t("components.modal.close") }}
      </Button>
      <div class="modal__content-inner">
        <slot name="modal-content"></slot>
      </div>
    </div>
    <div class="modal__backdrop" @click="onClose"></div>
  </div>
</template>

<script>
import Button from "./Button";

export default {
  components: {
    Button
  },
  props: {
    heading: {
      type: String,
      default: ""
    },
    showCloseButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onClose() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 100;

  &__content {
    width: calc(100% - 2rem);
    border-radius: 4px;
    background-color: #fff;
    padding: 1.5rem;
    z-index: 100;
    position: absolute;
    height: fit-content;
    max-height: calc(100% - 48px);
    overflow-y: scroll;
  }

  &__heading {
    font-size: 28px;
    font-weight: 600;
    margin-top: 2.5rem;
  }

  &__close-btn {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    background-color: #000;
    opacity: 0.2;
    z-index: 99;
  }
}

@media screen and (min-width: 900px) {
  .modal {
    &__content {
      min-width: 580px;
      max-width: 1200px;
      width: auto;
      border-radius: 4px;
      background-color: #fff;
      padding: 1.5rem;
      z-index: 100;
      position: absolute;
    }

    &__heading {
      font-size: 28px;
      font-weight: 600;
      margin-top: 0;
      width: 80%;
    }
  }
}
</style>
