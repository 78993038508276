import firebase from "firebase";

// firebase init goes here
const config = {
  apiKey: "AIzaSyDh5lF8I74lsRaqCSwN3081DO_umEIyxFo",
  authDomain: "lobbyspace2.firebaseapp.com",
  databaseURL: "https://lobbyspace2-europe.europe-west1.firebasedatabase.app",
  projectId: "lobbyspace2",
  storageBucket: "lobbyspace2",
  messagingSenderId: "849086585845",
  appId: "1:849086585845:web:c0003007541a940a0a1ebc"
};
firebase.initializeApp(config);

let provider = new firebase.auth.OAuthProvider("microsoft.com");
provider.setCustomParameters({
  prompt: "consent",
  tenant: "96ebb4db-d2f3-4fc4-b460-2c4b198ae9b8"
});

// firebase utils
const db = firebase.database();

// date issue fix according to firebase
/*const settings = {
    timestampsInSnapshots: true
}
db.settings(settings)
*/

// firebase collections
/*const usersCollection = db.collection('users')
const postsCollection = db.collection('posts')
const commentsCollection = db.collection('comments')
const likesCollection = db.collection('likes')
*/

const branchesRef = db.ref("branches");
const campaignsRef = db.ref("campaigns");
const codesRef = db.ref("codes");
const companyRef = db.ref("company");
const enterpriseRef = db.ref("enterprise");
const companyUsersRef = db.ref("companyUsers");
const invitesRef = db.ref("invites");
const postsRef = db.ref("posts");
const screensRef = db.ref("screens");
const userRef = db.ref("user");

export {
  provider,
  db,
  branchesRef,
  campaignsRef,
  codesRef,
  companyRef,
  enterpriseRef,
  companyUsersRef,
  invitesRef,
  postsRef,
  screensRef,
  userRef
};
