<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.card {
  @apply p-6 shadow-ls-shadow bg-white rounded;
}
</style>
