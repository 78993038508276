<template>
  <div class="notification" :class="classes">
    <div class="notification__text">
      <slot />
    </div>
    <div
      v-if="showCloseButton"
      @click="close"
      aria-label="close"
      class="notification__close"
    >
      <inline-svg
        class="notification-btn__icon"
        :src="require(`../assets/icons/X.svg`)"
        aria-label="Close"
      ></inline-svg>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export const notificationTypes = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info"
};

export default {
  name: "notification",
  props: {
    type: {
      type: String,
      default: notificationTypes.INFO,
      validator: value => Object.values(notificationTypes).includes(value)
    },
    title: {
      type: String
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    notificationId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    classes() {
      switch (this.type) {
        case "success":
          return "notification__success";
        case "info":
          return "notification__info";
        case "warning":
          return "notification__warning";
        case "error":
          return "notification__error";

        default:
          return "notification__info";
      }
    }
  },
  methods: {
    ...mapActions("notifications", ["removeNotification"]),
    close() {
      this.removeNotification(this.notificationId);
    }
  }
};
</script>

<style lang="scss" scoped>
.notification {
  @apply p-4 border border-gray-200 shadow-lg rounded-lg bg-white flex justify-between items-center z-50;
  bottom: 2rem;
  right: 3rem;
  min-width: 250px;

  &__close {
    @apply border-none outline-none cursor-pointer;
  }

  &__text {
    @apply text-ls-gray-800 font-semibold;
  }

  &__info {
  }

  &__success {
    @apply bg-green-200 border-none;

    .notification__title {
      @apply text-green-800;
    }
  }

  &__warning {
    @apply bg-yellow-200;

    .notification__title {
      @apply text-yellow-800;
    }
  }

  &__error {
    @apply bg-red-200;

    .notification__title {
      @apply text-red-800;
    }
  }
}
</style>
