<template>
  <div class="no-team">
    <Card>
      <div class="no-team__heading">Sie sind keinem Team zugeordnet</div>
      <Button class="no-team__logout-btn" @click="onLogout">Logout</Button>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Button from "@/components/Button";
import { mapActions } from "vuex";

export default {
  components: {
    Card,
    Button
  },
  methods: {
    ...mapActions("user", ["logout"]),
    onLogout() {
      this.logout();
    }
  }
};
</script>

<style lang="scss">
.no-team {
  width: 300px;
  margin: 0 auto;
  padding-top: 200px;

  &__logout-btn {
    @apply mt-1 w-full;
  }
}
</style>
