import firebase from "firebase";
import "firebase/storage";
import { create_UUID } from "../utils/utils";

import { db } from "../firebase/init";

export default {
  namespaced: true,
  state: {
    currentTeam: {},
    teams: [],
    rootTeam: null,
    parentTeam: null,
    parentBrand: null,
    childSettings: null,
    questionedTeam: null,
    role: null,
    generalAssets: null,
    credits: null
  },
  getters: {
    getCurrentTeam(state) {
      return state.currentTeam;
    },
    getTeams(state) {
      return state.teams;
    },
    getRootTeam(state) {
      return state.rootTeam;
    },
    getParentTeam(state) {
      return state.parentTeam;
    },
    getParentBrand(state) {
      return state.parentBrand;
    },
    getQuestionedTeam(state) {
      return state.questionedTeam;
    },
    getRole(state) {
      return state.role;
    },
    getChildSettings(state) {
      return state.childSettings;
    },
    getGeneralAssets(state) {
      return state.generalAssets;
    },
    getCredits(state) {
      return state.credits;
    }
  },
  mutations: {
    SET_CURRENT_TEAM(state, val) {
      state.currentTeam = val;
    },
    SET_TEAMS(state, val) {
      state.teams = val;
    },
    SET_QUESTIONED_TEAM(state, payload) {
      state.questionedTeam = payload;
    },
    SET_ROLE(state, payload) {
      state.role = payload;
    },
    SET_ROOT_TEAM(state, payload) {
      state.rootTeam = payload;
    },
    SET_PARENT_TEAM(state, payload) {
      state.parentTeam = payload;
    },
    SET_PARENT_BRAND(state, payload) {
      state.parentBrand = payload;
    },
    SET_CHILD_SETTINGS(state, payload) {
      state.childSettings = payload;
    },
    SET_GENERAL_ASSETS(state, payload) {
      state.generalAssets = payload;
    },
    SET_TEAM_CREDITS(state, payload) {
      state.credits = payload;
    },
    TEAM_LOGOUT(state) {
      state.currentTeam = {};
      state.teams = [];
      state.parentTeam = null;
      state.rootTeam = null;
      state.questionedTeam = null;
      state.role = null;
    }
  },
  actions: {
    async uploadTeamAvatar({ state, commit, dispatch }, image) {
      let team = state.currentTeam;

      let date = new Date();
      let seconds = date.getTime() / 1000;

      let blob = await fetch(image.src).then(r => r.blob());
      const uploadTask = firebase
        .storage()
        .ref()
        .child(`teams/${team.id}/avatar/${seconds}`)
        .put(blob);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          commit("progress/SET_PROGRESS_PICTURE", progress, { root: true });

          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        error => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log("File available at", downloadURL);

            team.avatar = downloadURL;

            firebase
              .database()
              .ref("teams/" + team.id)
              .update(team);

            dispatch("updateUsers", team);

            if (team.parentTeam) {
              dispatch("updateParentTeam", {
                parent: team.parentTeam,
                child: team.id,
                avatar: team.avatar,
                name: team.name
              });
            }
          });
        }
      );
    },
    async updateTeamAvatar({ state, commit, dispatch }, image) {
      let team = state.currentTeam;
      let date = new Date();
      let seconds = date.getTime() / 1000;
      const oldUrl = state.currentTeam.avatar;

      let blob = await fetch(image.src).then(r => r.blob());

      const uploadTask = firebase
        .storage()
        .ref()
        .child(`teams/${team.id}/avatar/${seconds}`)
        .put(blob);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          commit("progress/SET_PROGRESS_PICTURE", progress, { root: true });

          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        error => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log("File available at", downloadURL);

            team.avatar = downloadURL;

            firebase
              .database()
              .ref("teams/" + team.id)
              .update(team);

            dispatch("updateUsers", team);

            if (team.parentTeam) {
              dispatch("updateParentTeam", {
                parent: team.parentTeam,
                child: team.id,
                avatar: team.avatar,
                name: team.name
              });
            }

            firebase
              .storage()
              .refFromURL(oldUrl)
              .delete();
          });
        }
      );
    },
    async uploadTeamLogo({ state, commit }, image) {
      let team = state.currentTeam;
      let date = new Date();
      let seconds = date.getTime() / 1000;

      let blob = await fetch(image.src).then(r => r.blob());
      const uploadTask = firebase
        .storage()
        .ref()
        .child(`teams/${team.id}/brand/logo/${seconds}`)
        .put(blob);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          commit("progress/SET_PROGRESS_PICTURE", progress, { root: true });

          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        error => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log("File available at", downloadURL);

            let brand = {};
            if (state.currentTeam.brand) {
              brand = state.currentTeam.brand;
            }
            brand.logo = downloadURL;
            team.brand = brand;

            firebase
              .database()
              .ref("teams/" + team.id)
              .update(team);
          });
        }
      );
    },
    async uploadTeamLogoDark({ state, commit }, image) {
      let team = state.currentTeam;
      let date = new Date();
      let seconds = date.getTime() / 1000;

      let blob = await fetch(image.src).then(r => r.blob());
      const uploadTask = firebase
        .storage()
        .ref()
        .child(`teams/${team.id}/brand/logo/${seconds}_dark`)
        .put(blob);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          commit("progress/SET_PROGRESS_PICTURE", progress, { root: true });

          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        error => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log("File available at", downloadURL);

            let brand = {};
            if (state.currentTeam.brand) {
              brand = state.currentTeam.brand;
            }
            brand.logoDark = downloadURL;
            team.brand = brand;

            firebase
              .database()
              .ref("teams/" + team.id)
              .update(team);
          });
        }
      );
    },
    async updateTeamLogo({ state, commit }, image) {
      let team = state.currentTeam;
      let date = new Date();
      let seconds = date.getTime() / 1000;
      const oldUrl = state.currentTeam.brand.logo;

      let blob = await fetch(image.src).then(r => r.blob());

      const uploadTask = firebase
        .storage()
        .ref()
        .child(`teams/${team.id}/brand/logo/${seconds}`)
        .put(blob);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          commit("progress/SET_PROGRESS_PICTURE", progress, { root: true });

          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        error => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log("File available at", downloadURL);

            let brand = {};
            if (state.currentTeam.brand) {
              brand = state.currentTeam.brand;
            }
            brand.logo = downloadURL;
            team.brand = brand;

            firebase
              .database()
              .ref("teams/" + team.id)
              .update(team);

            firebase
              .storage()
              .refFromURL(oldUrl)
              .delete();
          });
        }
      );
    },
    async updateTeamLogoDark({ state, commit }, image) {
      let team = state.currentTeam;
      let date = new Date();
      let seconds = date.getTime() / 1000;
      const oldUrl = state.currentTeam.brand.logo;

      let blob = await fetch(image.src).then(r => r.blob());

      const uploadTask = firebase
        .storage()
        .ref()
        .child(`teams/${team.id}/brand/logo/${seconds}_dark`)
        .put(blob);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          commit("progress/SET_PROGRESS_PICTURE", progress, { root: true });

          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        error => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log("File available at", downloadURL);

            let brand = {};
            if (state.currentTeam.brand) {
              brand = state.currentTeam.brand;
            }
            brand.logoDark = downloadURL;
            team.brand = brand;

            firebase
              .database()
              .ref("teams/" + team.id)
              .update(team);

            firebase
              .storage()
              .refFromURL(oldUrl)
              .delete();
          });
        }
      );
    },
    async uploadFont({ state, commit, dispatch }, font) {
      let team = state.currentTeam;
      let date = new Date();
      let id = date.getTime();
      const metaData = {
        contentType: font.file.type
      };
      let blob = new Blob([font.file]);

      const uploadTask = firebase
        .storage()
        .ref()
        .child(`teams/${team.id}/brand/fonts/${font.name}`)
        .put(blob, metaData);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          commit("progress/SET_PROGRESS_PICTURE", progress, { root: true });

          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        error => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then(downloadURL => {
              dispatch(
                "notifications/addNotification",
                {
                  title: "fontAdded",
                  type: "success",
                  autoRemove: true
                },
                { root: true }
              );

              let brand = {};

              if (state.currentTeam.brand) {
                brand = state.currentTeam.brand;
              }

              let newFont = {
                name: font.name,
                createdAt: font.createdAt,
                url: downloadURL
              };

              if (brand.fonts) {
                brand.fonts[id] = newFont;
              } else {
                let fonts = {};
                fonts[id] = newFont;
                brand.fonts = fonts;
              }

              team.brand = brand;

              firebase
                .database()
                .ref("teams/" + team.id)
                .update(team);
            })
            .catch(() => {
              dispatch(
                "notifications/addNotification",
                {
                  title: "error",
                  type: "error",
                  autoRemove: true
                },
                { root: true }
              );
            });
        }
      );
    },
    async deleteFont({ state }, fontId) {
      let team = state.currentTeam;
      let fonts = team.brand.fonts;

      firebase
        .storage()
        .refFromURL(fonts[fontId].url)
        .delete()
        .then(() => {
          delete fonts[fontId];

          team.brand.fonts = fonts;

          firebase
            .database()
            .ref("teams/" + team.id)
            .update(team);
        });
    },
    fetchTeamCredits({ state, commit }) {
      let team = state.currentTeam;
      const teamPlanRef = db.ref(`plans/${team.id}/AICredits`);

      return new Promise(resolve => {
        teamPlanRef.on("value", snapshot => {
          let data = snapshot.val();

          if (data?.available === null || data?.available === undefined) {
            commit("SET_TEAM_CREDITS", 0);
          } else {
            commit("SET_TEAM_CREDITS", data.available);
          }
          resolve();
        });
      });
    },
    updateUsers(_, updatedTeam) {
      let getUsersPromises = [];
      let updateUsersPromises = [];

      let userList = Object.keys(updatedTeam.users);
      userList.map(user => {
        let userRef = db.ref(`users/${user}`).get();
        getUsersPromises.push(userRef);
      });

      Promise.all(getUsersPromises).then(snapshot => {
        snapshot.map(userSnapshot => {
          let user = userSnapshot.val();
          if (user.teams) {
            user.teams.map(team => {
              if (team.id === updatedTeam.id) {
                team.name = updatedTeam.name;
                if (updatedTeam.avatar) {
                  team.avatar = updatedTeam.avatar;
                }
              }
            });
          }

          let updateRef = db.ref(`users/${user.user_id}`).update(user);
          updateUsersPromises.push(updateRef);
        });
      });

      Promise.all(updateUsersPromises);
    },
    updateParentTeam(_, payload) {
      let parentRef = db.ref(
        `teams/${payload.parent}/children/${payload.child}`
      );

      parentRef.update({
        avatar: payload.avatar,
        name: payload.name
      });
    },
    createFirstTeam({ dispatch }, payload) {
      const user = {
        email: payload.email,
        name: payload.name,
        role: "admin",
        id: payload.id
      };

      if (payload.sso) {
        user.sso = payload.sso;
      }

      let users = {};
      users[user.id] = user;
      let screenId = new Date().getTime();

      let screens = [];
      let screen = {
        name: "Screen 1",
        online: true,
        orientation: "landscape",
        id: String(screenId)
      };
      screens.push(screen);

      let brand = {
        textStyles: {
          styleTitle: {
            fontFamily: "title_font",
            fontName: "Inter",
            fontId: "00001",
            fontSize: "120",
            name: "Title"
          },
          styleHeadline: {
            fontFamily: "headline_font",
            fontName: "Inter",
            fontId: "00001",
            fontSize: "80",
            name: "Headline"
          },
          styleSubHeadline: {
            fontFamily: "subHeadline_font",
            fontName: "Inter",
            fontId: "00001",
            fontSize: "40",
            name: "Sub-Headline"
          },
          styleNormalText: {
            fontFamily: "normalText_font",
            fontName: "Inter",
            fontId: "00002",
            fontSize: "32",
            name: "Normal Text"
          },
          styleSmallText: {
            fontFamily: "smallText_font",
            fontName: "Inter",
            fontId: "00002",
            fontSize: "20",
            name: "Small Text"
          },
          styleCaption: {
            fontFamily: "caption_font",
            fontName: "Inter",
            fontId: "00003",
            fontSize: "16",
            name: "Caption"
          }
        },
        colors: {
          primary: {
            color: "#7E93FF"
          },
          textLight: {
            color: "#ffffff"
          },
          textDark: {
            color: "#000000"
          }
        }
      };

      db.ref("teams/")
        .push({
          name: payload.teamName,
          users: users,
          avatar: "",
          screens: screens,
          brand
        })
        .then(cred => {
          let user = {
            teams: [],
            currentTeam: cred.key
          };

          db.ref(`plans/${cred.key}`).set({
            AICredits: {
              available: 5,
              plan: 5
            }
          });

          user.teams.push({
            id: cred.key,
            name: payload.teamName,
            avatar: ""
          });

          dispatch("user/updateProfile", user, { root: true });
        });
    },
    createChildTeam({ state, rootState, dispatch }, teamName) {
      const user = {
        email: rootState.user.userProfile.email,
        name: rootState.user.userProfile.name,
        role: "admin",
        id: rootState.user.userProfile.user_id
      };

      if (rootState.user.userProfile.sso) {
        user.sso = rootState.user.userProfile.sso;
      }

      let users = {};
      users[user.id] = user;

      let id = new Date().getTime();

      let screens = [];
      let screen = {
        name: "Screen 1",
        online: true,
        orientation: "landscape",
        id: String(id)
      };
      screens.push(screen);

      let brand = {
        textStyles: {
          styleTitle: {
            fontFamily: "title_font",
            fontName: "Inter",
            fontId: "00001",
            fontSize: "120",
            name: "Title"
          },
          styleHeadline: {
            fontFamily: "headline_font",
            fontName: "Inter",
            fontId: "00001",
            fontSize: "80",
            name: "Headline"
          },
          styleSubHeadline: {
            fontFamily: "subHeadline_font",
            fontName: "Inter",
            fontId: "00001",
            fontSize: "40",
            name: "Sub-Headline"
          },
          styleNormalText: {
            fontFamily: "normalText_font",
            fontName: "Inter",
            fontId: "00002",
            fontSize: "32",
            name: "Normal Text"
          },
          styleSmallText: {
            fontFamily: "smallText_font",
            fontName: "Inter",
            fontId: "00002",
            fontSize: "20",
            name: "Small Text"
          },
          styleCaption: {
            fontFamily: "caption_font",
            fontName: "Inter",
            fontId: "00003",
            fontSize: "16",
            name: "Caption"
          }
        },
        colors: {
          primary: {
            color: "#7E93FF"
          },
          textLight: {
            color: "#ffffff"
          },
          textDark: {
            color: "#000000"
          }
        }
      };

      let subscriptions = [];
      let subscription = {
        active: true,
        id: id,
        name: "Screen 1"
      };
      subscriptions.push(subscription);

      let rootSubscriptions = [];
      let rootSubscription = {
        active: true,
        id: id,
        name: "Screen 1"
      };
      rootSubscriptions.push(rootSubscription);

      let rootTeamId = "";

      if (state.currentTeam.rootTeam) {
        rootTeamId = state.currentTeam.rootTeam;
      } else if (state.currentTeam.parentTeam) {
        rootTeamId = state.currentTeam.parentTeam;
      } else {
        rootTeamId = state.currentTeam.id;
      }

      db.ref("teams/")
        .push({
          name: teamName,
          users: users,
          parentTeam: state.currentTeam.id,
          avatar: "",
          screens: screens,
          subscriptions: subscriptions,
          rootSubscriptions: rootSubscriptions,
          brand,
          rootTeam: rootTeamId
          // showBrandImport: true
        })
        .then(cred => {
          let user = rootState.user.userProfile;
          user.teams.push({
            id: cred.key,
            name: teamName,
            avatar: ""
          });

          let team = state.currentTeam;
          let children = team.children ? team.children : {};
          children[cred.key] = {
            name: teamName,
            createTemplates: true,
            brandManager: true
            //parentTemplates: true
          };

          if (team.slides) {
            team.slides.map(slide => {
              if (slide.branches) {
                slide.branches.push({
                  active: false,
                  id: cred.key,
                  name: teamName,
                  avatar: ""
                });
              } else {
                slide.branches = [];
                slide.branches.push({
                  active: false,
                  id: cred.key,
                  name: teamName,
                  avatar: ""
                });
              }
            });
          }

          db.ref(`plans/${cred.key}`).set({
            AICredits: {
              available: 5,
              plan: 0
            }
          });

          dispatch("user/updateProfile", user, { root: true });
          dispatch("updateTeam", { ...team, children: children });
          if (rootTeamId !== team.id) {
            dispatch("updateRootTeamChildren", {
              teamId: rootTeamId,
              newTeamId: cred.key,
              newTeamName: teamName
            });
          }
          dispatch(
            "notifications/addNotification",
            {
              title: "childTeamCreated",
              type: "success",
              autoRemove: true
            },
            { root: true }
          );
          //window.location.reload();
        });
    },
    updateRootTeamChildren({ dispatch }, payload) {
      const teamRef = db.ref(`teams/${payload.teamId}`);
      teamRef.once("value", snapshot => {
        let team = snapshot.val();
        team.id = snapshot.key;

        let rootChildren = team.rootChildren ? team.rootChildren : {};
        rootChildren[payload.newTeamId] = {
          name: payload.newTeamName,
          createTemplates: true,
          brandManager: true
          //parentTemplates: true
        };

        if (team.slides) {
          team.slides.map(slide => {
            if (slide.branches) {
              slide.branches.push({
                active: false,
                id: payload.newTeamId,
                name: payload.newTeamName,
                avatar: ""
              });
            } else {
              slide.branches = [];
              slide.branches.push({
                active: false,
                id: payload.newTeamId,
                name: payload.newTeamName,
                avatar: ""
              });
            }
          });
        }

        dispatch("updateTeam", { ...team, rootChildren });
      });
    },
    updateTeam({ dispatch }, team) {
      return new Promise((resolve, reject) => {
        db.ref("teams/" + team.id)
          .update(team)
          .then(() => {
            resolve();
            if (team.parentTeam) {
              if (team.avatar) {
                dispatch("updateParentTeam", {
                  parent: team.parentTeam,
                  child: team.id,
                  avatar: team.avatar,
                  name: team.name
                });
              } else {
                dispatch("updateParentTeam", {
                  parent: team.parentTeam,
                  child: team.id,
                  avatar: "",
                  name: team.name
                });
              }
            }
            dispatch("updateUsers", team);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    toggleScreen(_, team) {
      return new Promise((resolve, reject) => {
        db.ref("teams/" + team.id)
          .update(team)
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    deleteTeamFromUser(_, payload) {
      const userRef = db.ref("users/" + payload.userId);

      userRef.get().then(snapshot => {
        return new Promise((resolve, reject) => {
          const user = snapshot.val();

          let teams = user.teams.filter(team => team.id !== payload.teamId);
          let currentTeam = "";

          if (teams.length > 0) {
            currentTeam = teams[0].id;
          }

          userRef
            .update({
              ...user,
              teams: teams,
              currentTeam: currentTeam
            })
            .then(() => {
              resolve();
            })
            .catch(err => {
              reject(err);
            });
        });
      });
    },
    fetchGeneralAssets({ commit }) {
      return new Promise(resolve => {
        const assetsRef = db.ref("generalAssets");
        assetsRef.on("value", snapshot => {
          const data = snapshot.val();
          resolve(data);
          commit("SET_GENERAL_ASSETS", data);
        });
      });
    },
    fetchTeam({ commit }, teamId) {
      return new Promise(resolve => {
        const teamRef = db.ref(`teams/${teamId}`);
        teamRef.on("value", snapshot => {
          const data = snapshot.val();
          data.id = snapshot.key;
          resolve(data);
          commit("SET_QUESTIONED_TEAM", data);
        });
      });
    },
    setCurrentTeam({ dispatch, rootState }, teamId) {
      db.ref("users/" + rootState.user.currentUser.uid).update({
        ...rootState.user.userProfile,
        currentTeam: teamId
      });
      sessionStorage.setItem("currentTeam", teamId);
      dispatch("fetchCurrentTeam", teamId);
    },
    fetchCurrentTeam({ commit, dispatch, rootState }, teamId) {
      const teamRef = db.ref(`teams/${teamId}`);

      return new Promise(resolve => {
        teamRef.on("value", snapshot => {
          let data = snapshot.val();
          data.id = snapshot.key;
          if (data.brand?.colors) {
            if (Array.isArray(data.brand.colors)) {
              let newColors = {};

              data.brand.colors.forEach(color => {
                newColors[create_UUID()] = {
                  color: color
                };
              });

              delete data.brand.colors;
              data.brand.colors = newColors;
            }

            if (!data.brand?.colors?.primary) {
              data.brand.colors.primary = {
                color: "#7E93FF"
              };

              data.brand.colors.textLight = {
                color: "#ffffff"
              };

              data.brand.colors.textDark = {
                color: "#000000"
              };

              dispatch("updateTeam", data);
            }
          } else {
            data.brand = {
              ...data.brand,
              colors: {
                primary: {
                  color: "#7E93FF"
                },
                textLight: {
                  color: "#ffffff"
                },
                textDark: {
                  color: "#000000"
                }
              }
            };
            dispatch("updateTeam", data);
          }

          commit("SET_CURRENT_TEAM", data);
          resolve();
          if (data.parentTeam) {
            dispatch("fetchParentTeam", data.parentTeam);
          }

          if (data.rootTeam) {
            dispatch("fetchRootTeam", data.rootTeam);
          }

          let userList = Object.keys(data.users);
          userList.map(user => {
            if (user === rootState.user.userProfile?.user_id) {
              commit("SET_ROLE", data.users[user].role);
            }
          });
        });
      });
    },
    fetchParentTeam({ commit, state }, teamId) {
      return new Promise(resolve => {
        const teamRef = db.ref(`teams/${teamId}`);
        teamRef.on("value", snapshot => {
          const data = snapshot.val();
          commit("SET_PARENT_TEAM", {
            avatar: data.avatar ? data.avatar : "",
            name: data.name,
            id: data.id,
            children: data.children,
            rootChildren: data.rootChildren ? data.rootChildren : {},
            slides: data.slides && data.slides.length > 0 ? data.slides : [],
            tags: data.tags ? data.tags : {}
          });

          commit("SET_CHILD_SETTINGS", data.children[state.currentTeam.id]);
          if (!data.children[state.currentTeam.id].brandManager) {
            commit("SET_PARENT_BRAND", data.brand);
          }
          resolve();
        });
      });
    },
    fetchRootTeam({ commit }, teamId) {
      return new Promise(resolve => {
        const teamRef = db.ref(`teams/${teamId}`);
        teamRef.on("value", snapshot => {
          const data = snapshot.val();
          commit("SET_ROOT_TEAM", {
            avatar: data.avatar ? data.avatar : "",
            name: data.name,
            id: data.id,
            children: data.children,
            rootChildren: data.rootChildren ? data.rootChildren : {},
            slides: data.slides && data.slides.length > 0 ? data.slides : [],
            tags: data.tags ? data.tags : {}
          });

          resolve();
        });
      });
    },
    teamLogout({ commit }) {
      commit("TEAM_LOGOUT");
    }
  }
};
