<template>
  <div class="loading-overlay">
    <div class="loading-overlay__lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.loading-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0px;
  left: 0px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;

  &__lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  &__lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  &__lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  &__lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  &__lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
