import firebase from "firebase";
import "firebase/storage";

import { db } from "../firebase/init";

export default {
  namespaced: true,
  state: {
    invite: null
  },
  getters: {
    getInvite(state) {
      return state.invite;
    }
  },
  mutations: {
    SET_INVITE(state, payload) {
      state.invite = payload;
    }
  },
  actions: {
    inviteUser(_, invitation) {
      return new Promise((resolve, reject) => {
        const inviteRef = db.ref(`invites/${invitation.id}`);
        inviteRef
          .set(invitation)
          .then(() => resolve())
          .catch(err => reject(err));
      });
    },
    checkUser(_, email) {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .fetchSignInMethodsForEmail(email)
          .then(result => {
            result.length > 0 ? resolve(true) : resolve(false);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    deleteInvite({ commit }, invitation) {
      return new Promise(resolve => {
        const inviteRef = db.ref(`invites/${invitation}`);
        inviteRef.remove().then(() => {
          commit("SET_INVITE", null);
          resolve();
        });
      });
    },
    fetchInvite({ commit }, inviteId) {
      const inviteRef = db.ref(`invites/${inviteId}`);
      inviteRef.on("value", snapshot => {
        const data = snapshot.val();
        if (data) {
          data.id = snapshot.key;
        }

        commit("SET_INVITE", data);
      });
    }
  }
};
