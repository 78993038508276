<template>
  <div class="create">
    <div class="create__heading" v-if="showHeading">
      {{ $t("components.create.heading") }}
    </div>
    <div class="create__subHeading">
      {{ $t("components.create.subHeading") }}
    </div>
    <div
      class="create__options"
      :class="{ 'create__non-triplet': !isMultipleOfThree }"
    >
      <div class="create__option" @click="onCreate('image')">
        <div class="create__button create__button--image">
          <inline-svg
            class="create__icon"
            :src="require(`../assets/icons/Image_big.svg`)"
            aria-label="Image"
          ></inline-svg>
        </div>
        {{ $t("components.create.image") }}
      </div>

      <div class="create__option" @click="onCreate('video')">
        <div class="create__button create__button--video">
          <inline-svg
            class="create__icon"
            :src="require(`../assets/icons/Video_big.svg`)"
            aria-label="Video"
          ></inline-svg>
        </div>
        {{ $t("components.create.video") }}
      </div>

      <div class="create__option" @click="onCreate('pdf')">
        <div class="create__button create__button--pdf">
          <inline-svg
            class="create__icon"
            :src="require(`../assets/icons/PDF.svg`)"
            aria-label="PDF"
          ></inline-svg>
        </div>
        {{ $t("components.create.pdf") }}
      </div>

      <div
        class="create__option"
        v-for="option in widgetArray"
        :key="option.name"
        @click="onCreate(option.name)"
      >
        <div class="create__button" :style="{ backgroundColor: option.color }">
          <inline-svg
            class="create__icon"
            :src="option.icon"
            :aria-label="option.name"
          ></inline-svg>
        </div>
        {{ $t(`components.create.${option.name}`) }}
      </div>

      <div class="create__option" @click="onCreate('wallsio')">
        <div class="create__button create__button--wallsio">
          <inline-svg
            class="create__icon--wallsio"
            :src="require(`../assets/icons/Wallsio.svg`)"
            aria-label="wallsio"
          ></inline-svg>
        </div>
        {{ $t("components.create.wallsio") }}
      </div>

      <div class="create__option" @click="onCreate('webview')">
        <div class="create__button create__button--webview">
          <inline-svg
            class="create__icon"
            :src="require(`../assets/icons/Webview.svg`)"
            aria-label="webview"
          ></inline-svg>
        </div>
        {{ $t("components.create.webview") }}
      </div>
    </div>

    <div class="create__templates-wrapper" v-if="templates.length > 0">
      <div class="create__subHeading">
        {{ $t("components.create.templates") }}
      </div>
      <div class="create__templates">
        <div
          class="create__template"
          v-for="template in sortedTemplates"
          :key="template.id"
        >
          <Template
            :template="template.data"
            :is-parent="false"
            :id="template.id"
            :team-id="team.id"
          />
        </div>
      </div>
    </div>

    <div
      class="create__templates-by"
      v-if="
        team.parentTeam &&
          this.childSettings &&
          this.childSettings.parentTemplates &&
          sortedParentTemplates.length > 0
      "
    >
      <div class="create__subHeading">
        {{ $t("components.create.templatesBy") }} {{ parentTeam.name }}
      </div>
      <div class="create__templates">
        <div
          class="create__template"
          v-for="template in sortedParentTemplates"
          :key="template.id"
        >
          <Template
            :template="template.data"
            :is-parent="true"
            :id="template.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Template from "./Template.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Template
  },
  props: {
    showHeading: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    search: ""
  }),
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      parentTeam: "getParentTeam",
      childSettings: "getChildSettings"
    }),
    ...mapGetters("slides", {
      widgets: "getWidgetData"
    }),
    ...mapGetters("templates", {
      templates: "getTemplates",
      parentTemplates: "getParentTemplates"
    }),
    ...mapGetters("user", {
      user: "getUserProfile"
    }),
    sortedTemplates() {
      let t = [...this.templates];

      return t
        .sort((a, b) => {
          return b.data.lastChangedDate.localeCompare(a.data.lastChangedDate);
        })
        .filter(
          template =>
            template.data?.status !== "archived" &&
            template.data?.status !== "deleted"
        );
    },
    sortedParentTemplates() {
      let t = [...this.parentTemplates];

      return t
        .sort((a, b) => {
          return b.data.lastChangedDate.localeCompare(a.data.lastChangedDate);
        })
        .filter(
          template =>
            template.data?.status !== "archived" &&
            template.data?.status !== "deleted"
        );
    },
    widgetArray() {
      if (this.widgets) {
        return Object.entries(this.widgets)
          .map(([id, value]) => ({
            name: id,
            ...value
          }))
          .filter(option => option.public);
      }
      return [];
    },
    isMultipleOfThree() {
      return (this.widgetArray.length + 5) % 3 === 0;
    }
  },
  mounted() {
    const storedTeam = sessionStorage.getItem("currentTeam");

    if (!this.user?.currentTeam && !storedTeam) {
      this.fetchUserProfile().then(() => {
        this.fetchCurrentTeam(this.user.currentTeam).then(() => {
          this.fetchTemplates(this.team.id);
          this.fetchWidgetData();
          if (this.team.parentTeam) {
            this.fetchParentTeam(this.team.parentTeam).then(() => {
              if (this.childSettings && this.childSettings.parentTemplates) {
                this.fetchParentTemplates(this.team.parentTeam);
              }
            });
          }
        });
      });
    } else if (storedTeam) {
      this.fetchCurrentTeam(storedTeam).then(() => {
        this.fetchTemplates(this.team.id);
        this.fetchWidgetData();
        if (this.team.parentTeam) {
          this.fetchParentTeam(this.team.parentTeam).then(() => {
            if (this.childSettings && this.childSettings.parentTemplates) {
              this.fetchParentTemplates(this.team.parentTeam);
            }
          });
        }
      });
    } else {
      this.fetchCurrentTeam(this.user.currentTeam).then(() => {
        this.fetchTemplates(this.team.id);
        this.fetchWidgetData();
        if (this.team.parentTeam) {
          this.fetchParentTeam(this.team.parentTeam).then(() => {
            if (this.childSettings && this.childSettings.parentTemplates) {
              this.fetchParentTemplates(this.team.parentTeam);
            }
          });
        }
      });
    }
  },
  methods: {
    ...mapActions("templates", ["fetchTemplates", "fetchParentTemplates"]),
    ...mapActions("teams", ["fetchCurrentTeam", "fetchParentTeam"]),
    ...mapActions("user", ["fetchUserProfile"]),
    ...mapActions("slides", ["setOriginPage", "fetchWidgetData"]),
    onCreate(file) {
      this.setOriginPage(this.$route.path);

      this.$router.push({
        name: "EditorNew",
        params: {
          file: file
        }
      });
    }
  }
};
</script>

<style lang="scss">
.create {
  @apply border-ls-gray-300 border flex flex-col rounded;

  &__heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #000;
    margin-bottom: 40px;
  }

  &__subHeading {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #000;
    margin-top: 16px;
  }

  &__templates {
    @apply mt-4;
  }

  &__templates {
    display: flex;
    flex-wrap: wrap;
  }

  &__templates-by {
    @apply mt-4;
  }

  &__template {
    cursor: pointer;
    width: 100%;
  }

  &__options {
    @apply mb-4;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;
  }

  &__non-triplet {
    &::after {
      content: "";
      flex-grow: 0.5;
    }
  }

  &__option {
    @apply mb-1 text-center text-sm font-normal cursor-pointer;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &__button {
    @apply px-4 py-5;
    margin-bottom: 10px;
    border-radius: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.08);
    box-shadow: rgb(0 0 0 / 4%) 0px 1px 4px;
    &--image {
      @apply bg-ls-purple;
    }

    &--video {
      @apply bg-ls-pink;
    }

    &--pdf {
      background: #dc544a;
    }

    &--webview {
      @apply bg-ls-yellow;
    }
  }

  &__icon {
    width: 40px;
    height: 32px;

    path {
      fill: #fff;
    }
  }

  &__icon--wallsio {
    width: 40px;
    height: 32px;
  }
}

@media screen and (min-width: 900px) {
  .create {
    @apply p-6;

    &__heading {
      font-size: 28px;
      line-height: 32px;
    }

    &__subHeading {
      margin: 0;
    }

    &__template {
      @apply mr-4;
      width: auto;
    }

    &__options {
      @apply mb-12;
    }

    &__option {
      @apply mr-8;

      &:nth-child(3n) {
        @apply mr-8;
      }
    }
  }
}
</style>
