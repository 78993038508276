import Vue from "vue";
import Vuex from "vuex";

import user from "./user";
import teams from "./teams";
import invites from "./invites";
import screens from "./screens";
import notifications from "./notifications";
import slides from "./slides";
import progress from "./progress";
import templates from "./templates";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    user,
    teams,
    invites,
    screens,
    notifications,
    slides,
    progress,
    templates
  }
});
