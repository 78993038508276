<template>
  <div class="brand-import">
    <Card class="brand-import__card">
      <div class="brand-import__header">
        <img src="@/assets/img/import-brand.svg" alt="Import Brand" class="brand-import__image" />
        <div class="brand-import__logo-container" :class="{ 'pulse-animation': isImporting }">
          <img v-if="getCurrentTeam.brand && getCurrentTeam.brand.logo" :src="getCurrentTeam.brand.logo" alt="Company Logo" class="brand-import__logo" />
          <ReplacementLogo v-else :name="getCurrentTeam.name" class="brand-import__logo" />
        </div>
        <div class="brand-import__color-swatches" :class="{ 'pulse-animation': isImporting }">
          <div class="brand-import__color-swatch" :style="{ backgroundColor: getCurrentTeam.brand?.colors?.primary?.color }"></div>
          <div class="brand-import__color-swatch" :style="{ backgroundColor: getCurrentTeam.brand?.colors?.textDark?.color }"></div>
          <div class="brand-import__color-swatch" :style="{ backgroundColor: getCurrentTeam.brand?.colors?.textLight?.color }"></div>
        </div>
        <div class="brand-import__font-samples" :class="{ 'pulse-animation': isImporting }">
          <span 
            class="brand-import__font-sample brand-import__font-sample--title"
            :style="{
              fontFamily: getCurrentTeam.brand?.textStyles?.styleTitle?.fontFamily,
              fontSize: getCurrentTeam.brand?.textStyles?.styleTitle?.fontSize + 'px',
              fontWeight: getCurrentTeam.brand?.textStyles?.styleTitle?.fontWeight
            }"
          >
            Abc
          </span>
          <span 
            class="brand-import__font-sample brand-import__font-sample--normal"
            :style="{
              fontFamily: getCurrentTeam.brand?.textStyles?.styleNormalText?.fontFamily,
              fontSize: getCurrentTeam.brand?.textStyles?.styleNormalText?.fontSize + 'px',
              fontWeight: getCurrentTeam.brand?.textStyles?.styleNormalText?.fontWeight
            }"
          >
            Xyz
          </span>
        </div>
      </div>
      <template v-if="!isProcessing && !isSuccess">
        <h1 class="brand-import__title">{{ $t("pages.brandImport.title") }}</h1>
        <p class="brand-import__description">
          {{ $t("pages.brandImport.description") }}
        </p>
        <form @submit.prevent="importBrand" class="brand-import__form">
          <div class="brand-import__field">
            <input
              id="websiteUrl"
              v-model="websiteUrl"
              type="url"
              class="brand-import__input"
              :placeholder="$t('pages.brandImport.websiteUrlPlaceholder')"
              required
            />
          </div>
          <p class="brand-import__fineprint text__small--regular">
            {{ $t("pages.brandImport.permissionText") }}
          </p>
          <div class="brand-import__buttons">
            <Button @click="skipImport" theme="secondary" class="brand-import__button">
              {{ $t("pages.brandImport.skipButton") }}
            </Button>
            <Button type="submit" class="brand-import__button" :disabled="isImporting">
              {{ isImporting ? $t("pages.brandImport.importingButton") : $t("pages.brandImport.importButton") }}
            </Button>
          </div>
        </form>
      </template>
      <template v-else-if="isProcessing">
        <h1 class="brand-import__title">{{ $t("pages.brandImport.processingTitle") }}</h1>
        <p class="brand-import__description">
          {{ $t("pages.brandImport.processingDescription") }}
        </p>
        <div class="brand-import__progress-bar">
          <div class="brand-import__progress-bar-fill" :style="{ width: `${progress}%` }"></div>
        </div>
      </template>
      <template v-else>
        <h1 class="brand-import__title">{{ $t("pages.brandImport.successTitle") }}</h1>
        <p class="brand-import__description">
          {{ $t("pages.brandImport.successDescription") }}
        </p>
        <Button @click="goToDashboard" class="brand-import__button">
          {{ $t("pages.brandImport.continueButton") }}
        </Button>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Button from "@/components/Button";
import ReplacementLogo from "@/components/ReplacementLogo";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Card,
    Button,
    ReplacementLogo
  },
  data() {
    return {
      websiteUrl: "",
      isImporting: false,
      isProcessing: false,
      isSuccess: false,
      progress: 0,
      progressInterval: null
    };
  },
  computed: {
    ...mapGetters("teams", ["getCurrentTeam"])
  },
  methods: {
    ...mapActions("teams", ["updateTeam"]),
    async importBrand() {
      this.isImporting = true;
      this.isProcessing = true;
      this.startProgressBar();
      try {
        const response = await fetch(
          "https://europe-west3-lobbyspace2.cloudfunctions.net/importBrandKit",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              website: this.websiteUrl,
              teamId: this.getCurrentTeam.id
            })
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        
        // Assuming the API returns the updated team data
        await this.updateTeam({
          ...this.getCurrentTeam,
          ...data,
          showBrandImport: false
        });
        
        this.isSuccess = true;
      } catch (error) {
        console.error("Error importing brand:", error);
        // Optionally, you can add an error message here
        // this.$toast.error("Failed to import brand website");
      } finally {
        this.stopProgressBar();
        this.isImporting = false;
        this.isProcessing = false;
      }
    },
    skipImport() {
      // Update the team to disable future brand imports
      this.updateTeam({
        ...this.getCurrentTeam,
        showBrandImport: false
      });
      this.$router.push({ name: "Dashboard" });
    },
    setFonts() {
      console.log("setFonts method called");
      console.log("getCurrentTeam:", this.getCurrentTeam);
      if (this.getCurrentTeam.brand && this.getCurrentTeam.brand.textStyles) {
        let textStyles = Object.entries(this.getCurrentTeam.brand.textStyles);
        console.log("textStyles:", textStyles);
        let newStyle = document.createElement("style");
        let fonts = this.getCurrentTeam.brand.fonts || {};
        console.log("fonts:", fonts);

        textStyles.forEach(([key, font]) => {
          console.log("Processing font:", key, font);
          newStyle.appendChild(
            document.createTextNode(
              `
              @font-face {
                font-family: '${font.fontFamily}';
                src: url('${fonts[font.fontId]?.url}') format('woff');
              }
              `
            )
          );
        });

        console.log("Appending style to head:", newStyle.textContent);
        document.head.appendChild(newStyle);
      } else {
        console.log("No brand or textStyles available");
      }
    },
    goToDashboard() {
      this.$router.push({ name: "Dashboard" });
    },
    startProgressBar() {
      this.progress = 0;
      this.progressInterval = setInterval(() => {
        if (this.progress < 80) {
          this.progress += (80 / 60);
        }
      }, 1000);
    },
    stopProgressBar() {
      clearInterval(this.progressInterval);
      this.progress = 100;
    }
  },
  watch: {
    getCurrentTeam: {
      handler(newVal) {
        if (newVal && newVal.brand) {
          this.setFonts();
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    console.log("Component mounted");
    this.setFonts();
  },
  beforeUnmount() {
    this.stopProgressBar();
  }
};
</script>

<style lang="scss" scoped>
.brand-import {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__image {
    width: 100%;
  }

  &__card {
    width: 100%;
    max-width: 600px;
    padding: 0 2rem 2rem 2rem;
  }

  &__title {
    @apply text-2xl font-semibold mb-4;
  }

  &__description {
    @apply text-ls-gray-800 mb-6;
  }

  &__form {
    @apply flex flex-col;
  }

  &__field {
    @apply mb-4;
  }

  &__label {
    @apply block mb-2;
  }

  &__input {
    @apply w-full rounded-lg px-2 py-3 bg-ls-gray-100;
  }

  &__fineprint {
    @apply text-ls-gray-600 mb-6 text-sm;
  }

  &__buttons {
    @apply flex justify-between;
  }

  &__button {
    @apply w-48;
  }

  &__logo-container {
    @apply flex items-center;
    height: 76px;
    width: 76px;
    margin: auto;
    left: 40px;
    top: 0;
    bottom: 0;
  }

  &__color-swatches {
    @apply flex;
  }

  &__color-swatch {
    @apply rounded-full border border-gray-300;
    margin-right: -16px;
  }

  &__font-samples {
    @apply flex;
  }

  &__font-sample--title {
  }

  &__header {
    position: relative;
  }

  &__logo-container {
    position: absolute;
  }

  &__logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__color-swatches {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 60px;
    right: 0;
    left: 0;
    margin: auto;
    width: 148px;
    display: flex;
  }

  &__color-swatch {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  &__font-samples {
    position: absolute;
    top: 0;
    bottom: 0px;
    height: 96px;
    margin: auto;
    right: 40px;
    display: flex;
    flex-direction: column;
  }

  &__font-sample {
    margin-bottom: 0px;

    &--title {
      font-size: 52px !important;
      line-height: 52px !important;
    }

    &--normal {
      font-size: 52px !important;
      line-height: 52px !important;
      margin-left: 40px;
      margin-top: -16px;
    }
  }

  &__logo-container,
  &__color-swatches,
  &__font-samples {
    &.pulse-animation {
      animation: pulse 1.5s infinite;
    }
  }

  &__progress-bar {
    width: 100%;
    height: 4px;
    background-color: #e0e0e0;
    border-radius: 2px;
    overflow: hidden;
    margin-top: 1rem;
  }

  &__progress-bar-fill {
    @apply bg-ls-purple;
    height: 100%;
    transition: width 1s linear;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
</style>