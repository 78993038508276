import firebase from "firebase";
import "firebase/storage";
import dayjs from "dayjs";
import { db } from "../firebase/init";

export default {
  namespaced: true,
  state: {
    screenLicenses: null
  },
  getters: {
    getScreenLicenses(state) {
      return state.screenLicenses;
    }
  },
  mutations: {
    SET_SCREEN_LICENSES(state, payload) {
      state.screenLicenses = payload;
    }
  },
  actions: {
    createScreen({ rootState, dispatch }, screen) {
      return new Promise((resolve, reject) => {
        let team = rootState.teams.currentTeam;
        const screens = team.screens ? team.screens : [];
        screens.push(screen);

        firebase
          .database()
          .ref("teams/" + team.id)
          .update({
            screens: screens
          })
          .then(() => {
            resolve();
            if (team.slides && team.slides.length > 0) {
              team.slides.map(slide => {
                if (slide.screens) {
                  slide.screens.push({
                    active: false,
                    id: screen.id,
                    orientation: screen.orientation,
                    name: screen.name
                  });
                } else {
                  slide.screens = [];
                  slide.screens.push({
                    active: false,
                    id: screen.id,
                    orientation: screen.orientation,
                    name: screen.name
                  });
                }
              });

              dispatch("teams/updateTeam", { ...team }, { root: true });
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    updateScreens({ rootState, dispatch }) {
      let team = rootState.teams.currentTeam;
      const screens = team.screens;

      firebase
        .database()
        .ref("teams/" + team.id)
        .update({
          screens: screens
        })
        .then(() => {
          dispatch("teams/updateTeam", { ...team }, { root: true });
        });

      dispatch(
        "notifications/addNotification",
        {
          title: "screenUpdated",
          type: "success",
          autoRemove: true
        },
        { root: true }
      );
    },
    pairScreen({ rootState }, payload) {
      return new Promise((resolve, reject) => {
        let team = rootState.teams.currentTeam;
        let date = dayjs().format();

        let codeObject = {
          teamId: team.id,
          created: date,
          screenId: payload.screenId
        };

        firebase
          .database()
          .ref("codes/" + payload.code)
          .set(codeObject)
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    fetchScreensLicenses({ commit }, teamId) {
      const teamPlanRef = db.ref(`plans/${teamId}/screens`);

      return new Promise(resolve => {
        teamPlanRef.on("value", snapshot => {
          let data = snapshot.val();
          commit("SET_SCREEN_LICENSES", data);
          resolve(data);
        });
      });
    },
    updateScreensLicenses({ rootState, commit }, payload) {
      const teamPlanRef = db.ref(
        `plans/${rootState.teams.currentTeam.id}/screens`
      );

      return new Promise(resolve => {
        teamPlanRef.update(payload);
        commit("SET_SCREEN_LICENSES", payload);
        resolve(payload);
      });
    }
  }
};
