export default {
  namespaced: true,
  state: {
    notifications: []
  },
  getters: {
    getNotifications(state) {
      return state.notifications;
    }
  },
  mutations: {
    SET_NOTIFICATIONS(state, payload) {
      state.notifications.push(payload);
    },
    REMOVE_NOTIFICATION(state, id) {
      state.notifications = state.notifications.filter(note => {
        return note.id !== id;
      });
    }
  },
  actions: {
    addNotification({ commit, dispatch }, notification) {
      let seconds = new Date().getTime() / 1000;
      commit("SET_NOTIFICATIONS", {
        title: notification.title,
        type: notification.type,
        id: seconds
      });

      if (notification.autoRemove) {
        setTimeout(() => {
          dispatch("removeNotification", seconds);
        }, 4000);
      }
    },
    removeNotification({ commit }, notificationId) {
      commit("REMOVE_NOTIFICATION", notificationId);
    }
  }
};
