<template>
  <div class="alert">
    <div class="alert__content">
      <div class="alert__content--left">
        <inline-svg
          class="alert__warning-icon"
          :src="require('../assets/icons/ExclamationTriangle.svg')"
          aria-label="icon"
        ></inline-svg>
      </div>
      <div class="alert__content--right">
        <div class="alert__title">{{ title }}</div>
        <div class="alert__text">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss">
.alert {
  @apply rounded-md bg-yellow-50 p-4;

  &__content {
    @apply flex;

    &--left {
      @apply flex-shrink-0;
    }

    &--right {
      @apply ml-3;
    }
  }

  &__warning-icon {
    @apply text-yellow-400;

    path {
      @apply fill-current;
    }
  }

  &__title {
    @apply text-sm font-semibold text-yellow-800;
  }

  &__text {
    @apply mt-2 text-sm text-yellow-700;
  }
}
</style>
